import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

export const onPostWalkin = createAsyncThunk(
    'googler/onPostWalkin',
    async ({ eventId, channelId, code }: { eventId: string; channelId: string; code: string }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/googler/walkin`, { eventId, channelId, code })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export interface GooglerStates {
    walkin: {
        code: string
    }
    error: string | null
}

const initialState: GooglerStates = { walkin: { code: '' }, error: null }

const googlerSlice = createSlice({
    name: 'googler',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(onPostWalkin.fulfilled, (state, action) => {
                state.walkin.code = action.payload.walkinCode
                state.error = null
            })
            .addCase(onPostWalkin.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
    },
})

export default googlerSlice.reducer
