import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

interface Option {
    option: string
    isAnswer: boolean | string
}

export interface MiddleSurveys {
    surveyId: string
    _id: string
    category: string
    type: string
    order: number
    mustAnswer: boolean
    subject: string
    options: Option[]
    hadAsked: boolean
    eventId: string
}

export interface middleSurveyStates {
    middleSurveys: MiddleSurveys[]
    error: string | null
}

export const onGetAllMiddleSurvey = createAsyncThunk(
    'survey/getAllMiddleSurvey',
    async ({ eventId }: { eventId: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/middle-survey/all/${eventId}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: middleSurveyStates = { middleSurveys: [], error: null }

const middleSurveySlice = createSlice({
    name: 'postSurvey',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(onGetAllMiddleSurvey.fulfilled, (state, action) => {
                state.middleSurveys = action.payload.middleSurveys
                state.error = null
            })
            .addCase(onGetAllMiddleSurvey.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
    },
})

export default middleSurveySlice.reducer
