import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

interface Option {
    option: string
    isAnswer: boolean
}

export interface Survey {
    surveyId: string
    _id: string
    category: string
    type: string
    order: number
    mustAnswer: boolean
    subject: string
    options: Option[]
    hadAsked: boolean
    eventId: string
}

export interface SurveyStates {
    surveys: Survey[]
    error: string | null
}

export const onAddSurvey = createAsyncThunk(
    'survey/surveyAdd',
    async ({ survey }: { survey: Survey }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/survey/add`, {
                survey,
            })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onGetSurveys = createAsyncThunk(
    'survey/getSurveys',
    async ({ eventId }: { eventId: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/survey/surveys/${eventId}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onPatchSurvey = createAsyncThunk(
    'survey/patchSurvey',
    async ({ survey }: { survey: Survey }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`/survey/patch`, { survey })
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDeleteSurvey = createAsyncThunk(
    'survey/deleteSurvey',
    async ({ _id }: { _id: string }, { rejectWithValue }) => {
        try {
            const res = await axios.delete(`/survey/delete/${_id}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onUploadSurvey = createAsyncThunk(
    'survey/uploadSurvey',
    async ({ dataForm }: { dataForm: FormData }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/survey/upload/survey`, dataForm)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onGetAllPostSurvey = createAsyncThunk(
    'survey/getAllPostSurvey',
    async ({ eventId }: { eventId: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/survey/post/${eventId}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: SurveyStates = { surveys: [], error: null }

const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(onAddSurvey.fulfilled, (state, action) => {
                state.surveys = [...state.surveys, action.payload.survey]
                state.error = null
            })
            .addCase(onAddSurvey.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onGetSurveys.fulfilled, (state, action) => {
                state.surveys = action.payload.surveys
                state.error = null
            })
            .addCase(onGetSurveys.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })

            .addCase(onPatchSurvey.fulfilled, (state, action) => {
                const updatedSurveys = state.surveys.filter((survey) => {
                    return survey._id !== action.payload.survey._id
                })

                updatedSurveys.push(action.payload.survey)
                updatedSurveys.sort((a, b) => a.order - b.order)

                state.surveys = updatedSurveys
                state.error = null
            })
            .addCase(onPatchSurvey.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onDeleteSurvey.fulfilled, (state, action) => {
                const updatedSurveys = state.surveys.filter((survey) => {
                    return survey._id !== action.payload.deletedId
                })
                state.surveys = updatedSurveys
                state.error = null
            })
            .addCase(onDeleteSurvey.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onUploadSurvey.fulfilled, (state, action) => {
                state.surveys = action.payload.surveys
                state.error = null
            })
            .addCase(onUploadSurvey.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onGetAllPostSurvey.fulfilled, (state, action) => {
                state.surveys = action.payload.surveys
                state.error = null
            })
            .addCase(onGetAllPostSurvey.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
    },
})

export default surveySlice.reducer
