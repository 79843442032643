// import { createStore, applyMiddleware, compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
// import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import sessionStorage from 'redux-persist/lib/storage/session'

import reducers from './slice'

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat([thunk /* logger */] as const),
    devTools: process.env.NODE_ENV !== 'production',
})

export type Dispatch = typeof store.dispatch
export type RootState = ReturnType<typeof reducers>
export const persistor = persistStore(store)
