import RocketLoop from '../json/RocketLoop.json'
import RocketOpening_640 from '../json/RocketOpening_640.json'
import RocketOpening from '../json/RocketOpening.json'
import LuckyDraw from '../json/LuckyDraw.json'
import hand from '../json/hand.json'
import loader from '../json/loader.json'
import kv from '../json/kv.json'

export const Links = {
    GOOGLE_PLAY_STORE_EXAMPLE: 'https://oss.uppmkt.com/soa/img/Frame.svg',
    LUCKY_DRAW: 'https://oss.uppmkt.com/soa/img/LuckyDraw_1_600px.gif',
    LUCKY_DRAW_RESULT: 'https://oss.uppmkt.com/soa/img/LuckyDraw_2_600px.gif',
    UNLOCK: 'https://oss.uppmkt.com/soa/img/UnlockMotion_1_600px.gif',
    UNLOCK_RESULT: 'https://oss.uppmkt.com/soa/img/UnlockMotion_2_600px.png',
    UNLOCK_LOCK: 'https://oss.uppmkt.com/soa/img/lock.svg',
    UNLOCK_ICON: 'https://oss.uppmkt.com/soa/img/resultUnlock.svg',
    UNLOCK_BG: 'https://oss.uppmkt.com/soa/img/lock-bg.png',
    DONE: 'https://oss.uppmkt.com/soa/img/done.gif',
    FRAME: 'https://oss.uppmkt.com/soa/img/Frame.svg',
}

export const LandingPageImages = {
    LOGO_GOOGLE_GRAY: 'https://oss.uppmkt.com/soa/img/logo_google_gray.svg',
    LOGO_GOOGLE_COLOR: 'https://oss.uppmkt.com/soa/img/google_color.svg',
    NETWORK: 'https://oss.uppmkt.com/soa/img/icon_group.svg',
    SURVEY_DONE: 'https://oss.uppmkt.com/soa/img/survey_done.gif',
    EVENT_KV: 'https://oss.uppmkt.com/soa/img/kv-test.svg',
    AGENDA_SPEAKER_CARD_DOT: 'https://oss.uppmkt.com/soa/img/card-dot.svg',
    LOGO_Google_AutomationUnboxed: 'https://oss.uppmkt.com/soa/img/Google_AutomationUnboxed.svg',
    EVENT_GOOGLE_TITLE: 'https://oss.uppmkt.com/2024/website/appacademy/google.svg',
    EVENT_TITLE: 'https://oss.uppmkt.com/2024/website/appacademy/event.svg',
    SAILING_ICON: 'https://oss.uppmkt.com/soa/img/FAB_noX.png',
}

export const LottieJSON = {
    loader,
    RocketLoop,
    RocketOpening_640,
    Preview: '/json/preview/Preview.json',
    KV_Lottie: '/json/kv/LandingKV.json',
    BrandingVideo: '/json/branding/Branding_video.json',
    RocketOpening,
    hand,
    LuckyDraw,
}
