import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../lib/axios'

type UserAward = { code: string; name: string }
interface consoleState {
    userAward: UserAward[]
    uploadCount: number
    usersCount: number
    deletedCount: number
    error: string | null
}

export const onUploadUsers = createAsyncThunk(
    'console/uploadUsers',
    async ({ dataForm }: { dataForm: FormData }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/console/upload/users`, dataForm)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDropUsers = createAsyncThunk('console/dropUsers', async (args: { eventId: string }, { rejectWithValue }) => {
    try {
        const res = await axios.delete(`/console/drop/users?eventId=${args.eventId}`)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onDeliverMiddleSurvey = createAsyncThunk(
    'console/deliverMiddleSurvey',
    async (args: { code: string; surveyId: string; socketId: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/console/deliver/survey/${args.code}&&&${args.surveyId}&&&${args.socketId}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onDeliverSocketDisconnect = createAsyncThunk(
    'console/deliverSocketDisconnect',
    async (args: { code: string; des: string }, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/console/deliver/socketdisconnet/${args.code}&&&${args.des}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onGetMiddleSurveyAward = createAsyncThunk<{ userAward: UserAward[] }, { category: string; winners: 0 }>(
    'console/getMiddleSurveyAward',
    async (args, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/console/award/users?category=${args.category}&winners=${args.winners}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const initialState: consoleState = {
    userAward: [],
    usersCount: 0,
    uploadCount: 0,
    deletedCount: 0,
    error: null,
}

export const consoleSlice = createSlice({
    name: 'consoleSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(onUploadUsers.pending, (state) => {
                state.usersCount = 0
                state.uploadCount = 0
                state.deletedCount = 0
                state.error = null
            })
            .addCase(onUploadUsers.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onUploadUsers.fulfilled, (state, action) => {
                state.usersCount = action.payload.usersCount
                state.uploadCount = action.payload.uploadSuccessUsers
                state.deletedCount = 0
                state.error = null
            })

            .addCase(onDropUsers.pending, (state) => {
                state.usersCount = 0
                state.uploadCount = 0
                state.deletedCount = 0
                state.error = null
            })
            .addCase(onDropUsers.fulfilled, (state, action) => {
                state.deletedCount = action.payload.deletedCount
                state.usersCount = action.payload.usersCount
                state.uploadCount = 0
                state.error = null
            })
            .addCase(onDropUsers.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onDeliverMiddleSurvey.fulfilled, (state) => {
                state.error = null
            })
            .addCase(onDeliverMiddleSurvey.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onDeliverSocketDisconnect.fulfilled, (state) => {
                state.error = null
            })
            .addCase(onDeliverSocketDisconnect.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onGetMiddleSurveyAward.fulfilled, (state, action) => {
                state.userAward = action.payload.userAward
                state.error = null
            })
            .addCase(onGetMiddleSurveyAward.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
    },
})

export default consoleSlice.reducer
