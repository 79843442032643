import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../lib/axios'
import { Event } from './eventSlice'

export interface User<T, U> {
    _id: T
    name: T
    company: T
    code: T
    avatar: T
    logo: T
    channelId: T
    googler: U
    helper: U
    eventId: T
    personalLink: T
    surveys: T[]
    postSurveys: U
    middleSurveys: U
    admin: U
    walkin: U
}

interface Link {
    [key: string]: string
}

export type SelectedOption = {
    option: string
    type: string
}

type Anima = {
    _id: string
    code: string
    agree: number
    disagree: number
    everAgree: boolean
}

export type SendingSurvey = {
    surveyId: string
    category: string
    selected: SelectedOption[] | string
    correct?: string[]
}
export interface UserStates {
    user: User<string, boolean>
    auth: boolean
    error: string | null
    lastLoginTime: number | null
    links: Link[]
    isCreatingGoogler: boolean
    isWalkin: boolean
    anima: Anima | null
}

const initialState: UserStates = {
    user: {
        _id: '',
        name: '',
        company: '',
        code: '',
        avatar: '',
        logo: '',
        channelId: '',
        googler: false,
        helper: false,
        eventId: '',
        personalLink: '',
        surveys: [],
        postSurveys: false,
        middleSurveys: false,
        admin: false,
        walkin: false,
    },
    auth: false,
    error: null,
    lastLoginTime: null,
    links: [],
    anima: null,
    isCreatingGoogler: false,
    isWalkin: false,
}

export const onUserLogin = createAsyncThunk<
    { isWalkin: boolean; isCreatingGoogler: boolean; user: User<string, boolean> },
    {
        loginCode: string
        searchQuery: string
        eventPackage: Event[]
        eventSlug: string
        googler: boolean
        channelId: string
    }
>('user/userLogin', async (args, { rejectWithValue }) => {
    const queryStr = new URLSearchParams(args.searchQuery).toString()
    try {
        const res = await axios.post(`/user/login/?${queryStr}`, {
            loginCode: args.loginCode,
            eventPackage: args.eventPackage,
            eventSlug: args.eventSlug,
            googler: args.googler,
            channelId: args.channelId,
        })
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onUserWalkinLogin = createAsyncThunk<
    { isWalkin: boolean; isCreatingGoogler: boolean; user: User<string, boolean> },
    {
        _id: string
        walkinName: string
        walkinCompany: string
        walkinEmail: string
        eventSlug: string
        eventPackage: Event[]
    }
>('user/userWalkin', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`/user/walkin`, {
            walkinName: args.walkinName,
            walkinCompany: args.walkinCompany,
            walkinEmail: args.walkinEmail,
            _id: args._id,
            eventSlug: args.eventSlug,
            eventPackage: args.eventPackage,
        })
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onUserGooglerLogin = createAsyncThunk<
    { isWalkin: boolean; isCreatingGoogler: boolean; user: User<string, boolean> },
    { _id: string; googlerName: string }
>('user/userGoogler', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`/user/googler`, {
            googlerName: args.googlerName,
            _id: args._id,
        })
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onUserAddPersonalLink = createAsyncThunk<
    { personalLink: string; links: Link[] },
    { _id: string; personalLink: string }
>('user/addPersonalLink', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`/user/link`, {
            _id: args._id,
            personalLink: args.personalLink,
        })
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onGetAllUsersPersonalLink = createAsyncThunk<{ links: Array<Link> }, void>(
    'user/getAllUsersPersonalLink',
    async (_args, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/user/links`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

export const onPostUserSurveyResult = createAsyncThunk<
    { surveyId: string },
    { sendingSurvey: SendingSurvey; userId: string; userCode: string; eventId: string }
>('user/postUserSurveyResult', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`/user/result`, args)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onPostUserEventSurveyResult = createAsyncThunk<
    { surveyId: string; isFinished: boolean; identifier: 'middle' | 'post' },
    {
        sendingData: { sendingSurvey: SendingSurvey; userId: string; userCode: string; eventId: string }[]
        isFinished: boolean
        identifier: 'middle' | 'post'
        discrepancy?: number
    }
>('user/postUserEventSurveyResult', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`/user/event-survey`, args)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onPostSwitchEventId = createAsyncThunk<
    { eventId: string; channelId: string },
    { updatedEventId: string; channelId: string; _id: string }
>('user/onPostSwitchEventId', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`/user/switch/event`, args)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onAgreeAnimation = createAsyncThunk<
    { anima: Anima },
    { code: string; agree: boolean; playStoreId: string }
>('user/agreeAnimation', async (args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`/user/agree`, args)
        return res.data
    } catch (error) {
        return rejectWithValue({ error: error.response.data.message })
    }
})

export const onCheckUser = createAsyncThunk<{ logo: string; anima: Anima | null }, { code: string }>(
    'user/checkUser',
    async (args, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/user/check?code=${args.code}`)
            return res.data
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message })
        }
    }
)

const INIT_USER = {
    _id: '',
    name: '',
    company: '',
    code: '',
    avatar: '',
    logo: '',
    channelId: '',
    googler: false,
    helper: false,
    eventId: '',
    personalLink: '',
    surveys: [],
    postSurveys: false,
    middleSurveys: false,
    admin: false,
    walkin: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        initUser(state) {
            localStorage.removeItem('persist:root')
            state.user = INIT_USER
            state.auth = false
            state.lastLoginTime = null
            state.error = null
            state.links = []
            state.isCreatingGoogler = false
            state.isWalkin = false
        },
        modifyEventAndChannelId(state, action) {
            state.user.eventId = action.payload.eventId
            state.user.channelId = action.payload.channelId
        },
        removeUserError(state) {
            state.error = null
        },
        setUserLoginError(state, action) {
            state.error = action.payload.error
            state.auth = false
        },

        switchEvent(state, action) {
            state.user.eventId = action.payload.eventId
            state.user.channelId = action.payload.channelId
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(onUserLogin.pending, (state) => {
                state.user = INIT_USER
                state.auth = false
                state.lastLoginTime = null
                state.error = null
            })
            .addCase(onUserLogin.fulfilled, (state, action) => {
                state.user = action.payload.user
                state.lastLoginTime = new Date().getTime()

                if (action.payload.isWalkin || action.payload.isCreatingGoogler) {
                    state.auth = false
                    state.user._id = action.payload.user._id
                    state.isWalkin = action.payload.isWalkin
                    state.isCreatingGoogler = action.payload.isCreatingGoogler
                } else {
                    state.auth = true
                    state.user = action.payload.user
                }

                state.error = null

                // ga('Login', 'login_success', state.user.code)
            })
            .addCase(onUserLogin.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
                state.user = INIT_USER
                state.auth = false
                state.lastLoginTime = null
            })
            .addCase(onUserWalkinLogin.fulfilled, (state, action) => {
                state.user = action.payload.user
                state.lastLoginTime = new Date().getTime()
                state.auth = true
                state.isWalkin = false
                state.error = null

                // ga('Login', 'login_success', state.user.code)
            })
            .addCase(onUserWalkinLogin.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
                state.user = INIT_USER
                state.auth = false
                state.isWalkin = false
                state.lastLoginTime = null
            })
            .addCase(onUserGooglerLogin.fulfilled, (state, action) => {
                state.user = action.payload.user
                state.lastLoginTime = new Date().getTime()
                state.auth = true
                state.isCreatingGoogler = false
                state.error = null

                // ga('Login', 'login_success', state.user.code)
            })
            .addCase(onUserGooglerLogin.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
                state.user = INIT_USER
                state.auth = false
                state.isCreatingGoogler = false
                state.lastLoginTime = null
            })
            .addCase(onUserAddPersonalLink.fulfilled, (state, action) => {
                state.user.personalLink = action.payload.personalLink
                state.links = action.payload.links
                state.error = null
            })
            .addCase(onUserAddPersonalLink.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
                state.user.personalLink = ''
            })
            .addCase(onGetAllUsersPersonalLink.fulfilled, (state, action) => {
                state.links = action.payload.links
                state.error = null
            })
            .addCase(onGetAllUsersPersonalLink.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onPostUserSurveyResult.fulfilled, (state, action) => {
                state.user.surveys = [...new Set([...state.user.surveys, action.payload.surveyId])]
                state.error = null
            })
            .addCase(onPostUserSurveyResult.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onPostUserEventSurveyResult.fulfilled, (state, action) => {
                state.user.surveys = [...new Set([...state.user.surveys, action.payload.surveyId])]
                if (action.payload.isFinished) {
                    if (action.payload.identifier === 'middle') state.user.middleSurveys = true
                    else state.user.postSurveys = true
                }
                state.error = null
            })
            .addCase(onPostUserEventSurveyResult.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })

            .addCase(onPostSwitchEventId.fulfilled, (state, action) => {
                state.user.eventId = action.payload.eventId
                state.user.channelId = action.payload.channelId
                state.error = null
            })
            .addCase(onPostSwitchEventId.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onAgreeAnimation.fulfilled, (state, action) => {
                state.anima = action.payload.anima
                state.error = null
            })
            .addCase(onAgreeAnimation.rejected, (state, action) => {
                state.error = (action.payload as { error: string }).error
            })
            .addCase(onCheckUser.fulfilled, (state, action) => {
                state.user.logo = action.payload.logo
                state.anima = action.payload.anima
                state.error = null
            })
    },
})

export const { initUser, removeUserError, modifyEventAndChannelId, setUserLoginError, switchEvent } = userSlice.actions
export default userSlice.reducer
