import { combineReducers } from '@reduxjs/toolkit'

import chatReducer from './chatSlice'
import consoleReducer from './consoleSlice'
import eventReducer from './eventSlice'
import jobsReducer from './jobsSlice'
import questionReducer from './questionSlice'
import surveyReducer from './surveySlice'
import userReducer from './userSlice'
import resultReducer from './resultSlice'
import agendaReducer from './agendaSlice'
import postSurveyReducer from './postSurveysSlice'
import middleSurveysSlice from './middleSurveysSlice'
import googlerSlice from './googlerSlice'
import drawingSlice from './drawingSlice'

export {
    initUser,
    modifyEventAndChannelId,
    onUserLogin,
    removeUserError,
    onUserAddPersonalLink,
    onGetAllUsersPersonalLink,
    onPostUserSurveyResult,
    onPostUserEventSurveyResult,
    setUserLoginError,
    onPostSwitchEventId,
    switchEvent,
    onUserWalkinLogin,
    onUserGooglerLogin,
    onAgreeAnimation,
    onCheckUser,
} from './userSlice'
export { addLocalMessage, onGetHistoryMessages, onGetRestHistoryMessages, updateLocalMessage } from './chatSlice'
export {
    onDropUsers,
    onUploadUsers,
    onDeliverMiddleSurvey,
    onDeliverSocketDisconnect,
    onGetMiddleSurveyAward,
} from './consoleSlice'
export { onGetEventDetail, onGetEventTimeReset, onUploadEvents } from './eventSlice'
export { onAddSurvey, onDeleteSurvey, onGetSurveys, onPatchSurvey, onUploadSurvey } from './surveySlice'
export {
    onDeleteJob,
    onGetJobsDefine,
    onGetJobsRedefine,
    onPatchJob,
    receiveJob,
    removeJobsError,
    onUploadCronJobs,
} from './jobsSlice'
export {
    addLocalQuestion,
    onGetHistoryQuestions,
    onGetRestHistoryQuestions,
    updateLocalQuestion,
} from './questionSlice'
export { onGetSurveyResult, onGetMiddleSurveyResult, removeResult, removeMiddleResult } from './resultSlice'
export { onGetAllPostSurvey } from './postSurveysSlice'
export { onGetAllMiddleSurvey } from './middleSurveysSlice'
export { initAgenda, forwardToCurrentAgenda, initSymbols } from './agendaSlice'
export { onPostWalkin } from './googlerSlice'
export { onPostAward, onGetAwards, onDeleteAward, initDrawing, onResetDrawing, updateWinners } from './drawingSlice'

export type { CronJobsFieldsValue, Jobs } from './jobsSlice'
export type { User, SelectedOption } from './userSlice'
export type { Message } from './chatSlice'
export type { Question } from './questionSlice'
export type { Survey } from './surveySlice'
export type { Event } from './eventSlice'
export type { MiddleSurveys } from './middleSurveysSlice'
export type { Award } from './drawingSlice'

const reducers = combineReducers({
    user: userReducer,
    chat: chatReducer,
    question: questionReducer,
    event: eventReducer,
    survey: surveyReducer,
    postSurvey: postSurveyReducer,
    middleSurvey: middleSurveysSlice,
    console: consoleReducer,
    jobs: jobsReducer,
    result: resultReducer,
    agenda: agendaReducer,
    googler: googlerSlice,
    drawing: drawingSlice,
})

export default reducers
